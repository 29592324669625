<form class="container" #myForm="ngForm">
    <mat-form-field appearance="outline">
        <mat-label>Naziv</mat-label>
        <input matInput [(ngModel)]="classroom.name" type="text" name="name" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Opis</mat-label>
        <input matInput [(ngModel)]="classroom.description" type="text" name="description" required/>
    </mat-form-field>
    <mat-chip-list>
        <mat-chip [disabled]="exists" color="accent" [selected]="classroom.type == 'virtual'" (click)="classroom.type = 'virtual'">Virtualna</mat-chip>
        <mat-chip [disabled]="exists" color="accent" [selected]="classroom.type == 'physical'" (click)="classroom.type = 'physical'">Fizička</mat-chip>
      </mat-chip-list>
      <small>Napomena: nije moguće mijenjati vrstu predavaonice nakon stvaranja</small>
    
      <mat-form-field appearance="outline" *ngIf="classroom.type == 'physical'">
        <mat-label>Lokacija / Adresa</mat-label>
        <input matInput [(ngModel)]="classroom.location" type="text" name="location" required/>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="classroom.type == 'virtual'">
        <mat-label>Link</mat-label>
        <input matInput [(ngModel)]="classroom.link" type="text" disabled/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Naslovna slika</mat-label>
        <input matInput type="text" name="image" required [(ngModel)]="classroom.image" disabled (click)="fileUpload.click()">
        <button mat-mini-fab matSuffix color="accent" (click)="fileUpload.click()"><mat-icon>attachment</mat-icon></button>
        <input type="file" class="file-input" style="display: none;"
       (change)="onFileSelected($event)" #fileUpload>
    </mat-form-field>

    <button mat-raised-button color="accent" style="margin-top: 40px" (click)="save()">Spremi promjene</button>
</form>
