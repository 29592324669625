import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notification } from 'models';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-my-notifications',
  templateUrl: './my-notifications.component.html',
  styleUrls: ['./my-notifications.component.css']
})
export class MyNotificationsComponent implements OnInit {

  constructor(
    public $router : Router,
    public $auth : AuthService,
    public $database : DatabaseService
  ){

  }

  ngOnInit(): void {
    this.notifications = this.$database.notifications.getValue().filter(n => n.participantId == this.$auth.participant.getValue().id).sort((a, b) => a.time > b.time ? 1 : -1);
  }

  public notifications : Notification[] = []
  filter = ''

  open(notification){
    this.$database.seeNotification(notification.id)
    console.log(notification.link)
    this.$router.navigateByUrl(notification.link)
  }
}
