import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { Classroom } from 'models';
import { DatabaseService } from 'src/app/database.service';
import { LoaderService } from 'src/app/loader.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-classrooms-edit',
  templateUrl: './classrooms-edit.component.html',
  styleUrls: ['./classrooms-edit.component.css']
})
export class ClassroomsEditComponent {
  constructor(
    public dialogRef: MatDialogRef<ClassroomsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $database : DatabaseService,
    public $loader : LoaderService
  ) {
    if(data.classroom){
      this.classroom = data.classroom;
      this.exists = true;
    } else {
      this.classroom = {
        id: Guid.create().toString(),
        name: "",
        image: "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: "",
        type: 'virtual',
        subjectsIds: [],
        location: '',
        link: 'automatski će se generirati stvaranjem...',
        projectId: data.projectId,
        eventsIds: [],
        messagesIds: [],
      }
      this.exists = false;
    }
  }

  public classroom : Classroom;
  public exists = false;
  
  @ViewChild('myForm') myForm: NgForm;
  public save(){

    if(!this.classroom.name || !this.classroom.description || (this.classroom.type == 'physical' && !this.classroom.location)){
      this.myForm.form.markAllAsTouched();
      this.$loader.toast('Nisu ispunjeni svi potrebni podatci!')
      return;
    }

    this.$loader.open()
    if(this.exists) {
      this.$database.updateClassroom(this.classroom.id, this.classroom.name, this.classroom.image, this.classroom.description, this.classroom.type, this.classroom.location)
      .then(() => {
        this.$loader.close('Podatci predavaonice uspješno ažurirani!')
      })
      this.dialogRef.close();
    } else {
      this.$database.createClassroom(this.classroom.name, this.classroom.image, this.classroom.description, this.classroom.type, this.data.projectId, this.classroom.location)
      .then(() => {
        this.$loader.close('Podatci predavaonice uspješno ažurirani!')
      })
      this.dialogRef.close();
    }
  }

  public onFileSelected(event){
    this.$loader.open()
    const file = event.target.files[0];
    if (file) {
      this.$database.pushFileToStorage(file, file.name).then((res) => {
        this.classroom.image = res as string;
        this.$loader.close('Datoteka uspješno uploadana!');
      })
    }
  }
  
}
