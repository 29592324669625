<div class="container">

    <div class="fx smlrimg" style="grid-column-gap: 20px;grid-row: 1; grid-column: 1 / span 6; display: grid; grid-template-columns: 1fr 4fr 1fr">
        <mat-card style="grid-column: 2;">
            <div class="footer-logo-2"></div>
        </mat-card>
        
    </div>

    <mat-card class="fx" style="grid-row: 2; grid-column: 3 / span 2;">
        <div class="logo"></div>
    </mat-card>

    <mat-card style="grid-row: 3; grid-column: 1 / span 2;">
        <a class="material" href="../../assets/raSTEMo roll up.pdf">raSTEMo roll-up</a>
    </mat-card >
    <mat-card style="grid-row: 3; grid-column: 3 / span 2;">
        <a class="material" href="../../assets/raSTEMo brošura.pdf">raSTEMo brošura
        </a>
    </mat-card>
    <mat-card style="grid-row: 3; grid-column: 5 / span 2;">
        <a class="material" href="https://ipemed.hr">IPEMED web stranica</a>
    </mat-card>


    <mat-card style="grid-row: 4; grid-column: 1 / span 3;">
        <h3>KODNI BROJ PROJEKTA:</h3>
        <p>UP.04.2.1.10.0149</p>
    </mat-card>
    <mat-card style="grid-row: 5; grid-column: 1 / span 3;">
        <h3>PROGRAM:</h3>
        <p>Operativni program „Učinkoviti ljudski potencijali 2014.-2020.“</p>
    </mat-card>
    <mat-card  style="grid-row: 6; grid-column: 1 / span 3;">
        <h3>KORISNIK:</h3>
        <p>Institut perspektiva ekonomije Mediterana</p>
    </mat-card>
    <mat-card style="grid-row: 7; grid-column: 1 / span 3;">
        <h3>PROJEKTNI PARTNERI:</h3>
        <p>Fakultet elektrotehnike, strojarstva i brodogradnje u Splitu</p>
        <p>Medicinski fakultet u Splitu</p>
    </mat-card>
    <mat-card  style="grid-row: 8; grid-column: 1 / span 3;">
        <h3>RAZDOBLJE PROVEDBE PROJEKTA:</h3>
        <p>22. kolovoza 2022. - 22. kolovoza 2024.</p>
    </mat-card>

    
    <mat-card style="grid-row: 4 / span 5; grid-column: 4 / span 3;">
        <h3>Ukupna vrijednost projekta (u HRK):</h3>
        <p>2.422.016,37 HRK</p>
        <br>
        <h3>Ukupna vrijednost projekta (u EUR):</h3>
        <p>321.456,81 EUR</p>
        <br>
        <h3>Bespovratna sredstva osigurana su iz (u HRK):</h3>
        <p>Europskog socijalnog fonda u iznosu od 2.058.713,91 HRK</p> 
        <p>Državnog proračuna u iznosu od 363.302,46 HRK</p>
        <br>
        <h3>Bespovratna sredstva osigurana su iz (u EUR):</h3>
        <p>Europskog socijalnog fonda u iznosu od 273.238,29 EUR</p> 
        <p>Državnog proračuna u iznosu od 48.218,52 EUR</p>
        <br>
        <p>Fiksni tečaj: 1 € = 7,53450 kn</p>

    </mat-card>
    <mat-card style="grid-row: 9; grid-column: 1 / span 3;">
        <h3>KRATAK OPIS PROJEKTA:</h3>
        <p>Projekt „Uz zdrav životni stil raSTEMo“ usmjeren je na jačanje kapaciteta i uloge organizacije civilnog društva IPEMED s ciljem aktivnog uključivanja djece, mladih te opće populacije u popularizaciju STEM-a na području medicinske senzorike i 3D tehnologija u medicini. Projektne aktivnosti podrazumijevaju provedbu programa mentorstva usmjereno na djelatnike i studente volontere udruge IPEMED, jednodnevnih i višednevnih događanja i radionica za djecu i mlade u području medicinske senzorike i 3D tehnologija u medicini, te uspostave inovativnih i interaktivnih trajnih postava na visokoobrazovnim institucijama, FESB-u i MEFST-u.</p>
    </mat-card>
    <mat-card style="grid-row: 9; grid-column: 4 / span 3;">
        <h3>Očekivani rezultati projekta:</h3>
        <p>Ojačani kapaciteti organizacije civilnog društva IPEMED za provedbu programa popularizacije STEM-a</p>
        <p>Organizirane i provedene višednevne lokalne radionice za učenike srednjih škola sa svrhom popularizacije STEM-a</p>
        <p> Organizirana i provedena jednodnevna događanja na lokalnoj razini za učenike osnovnih škola sa svrhom podizanja svijesti o važnosti STEM-a i znanstvenim postignućima iz STEM područja </p>
        <p> Uspostavljeni trajni postavi inovativnih i interaktivnih sadržaja sa svrhom popularizacije STEM-a među djecom i mladima te općom populacijom</p>
    </mat-card>
    <mat-card style="grid-row: 10; grid-column: 1 / span 6;">
        <h3>CILJ PROJEKTA JE:</h3>
        <p>Ojačati kapacitete udruge IPEMED za aktivno uključivanje mladih te opće populacije u popularizaciju STEM-a na području medicinske senzorike i 3D tehnologija u medicini te uključivanje krajnjih korisnika u aktivnosti popularizacije STEM-a kao i usvajanje znanja za daljnji profesionalni razvoj u STEM području te uspostava trajnog postava na MEFST-u i FESB-u.</p>
    </mat-card>
    <mat-card style="grid-row: 11; grid-column: 1 / span 6;">
        <h3>KONTAKTI ZA VIŠE INFORMACIJA:</h3>
        <h3>KORISNIK:</h3>
        <p>Institut perspektiva ekonomije Mediterana, Kroz Smrdečac 17, 21 000 Split<br>tel.: +385 21/259-806, e-mail: ured@ipemed.hr<br>Voditeljica projekta: Kristina Buzuk, dipl. oec., mob.: 091 444 1957, e-mail adresa: kristina@ipemed.hr</p>
        <h3>PROJEKTNI PARTNERI:</h3>
        <p>Fakultet elektrotehnike, strojarstva i brodogradnje u Splitu, Ulica Ruđera Boškovića 32, 21 000 Split<br>tel.: +385 021 305 777, e-mail adresa: psolic@fesb.hr<br>Odgovorna osoba: dr. sc. Petar Šolić, prodekan za poslovanje</p>
        <p>Medicinski fakultet u Splitu, Šoltanska ulica 2, 21 000 Split<br>tel.: +385 021 557 800, e-mail adresa: katarina.vukojevic@mefst.hr<br>Odgovorna osoba: prof. dr. Katarina Vukojević, prodekanica za znanost, poslijediplomske studije i međunarodnu suradnju</p>
    </mat-card>
    <mat-card style="grid-row: 12; grid-column: 1 / span 6;">
        <h3>MREŽNE STRANICE ZA VIŠE INFORMACIJA O EU FONDOVIMA:</h3>
        <p><a href="https://strukturnifondovi.hr">strukturnifondovi.hr</a> <br>
            <a href="http://www.esf.hr/operativni-program/">http://www.esf.hr/operativni-program/</a> </p>
    </mat-card>
    <mat-card style="grid-row: 13; grid-column: 1 / span 6;">
        <p style="text-align: center;">Mrežna platforma izrađena je u okviru projekta Uz zdrav životni stil raSTEMo.</p>
    </mat-card>
    <mat-card style="grid-row: 14; grid-column: 1 / span 6;">
        <p style="text-align: center;">Sadržaj mrežne platforme isključiva je odgovornost Instituta perspektive ekonomije Mediterana</p>
    </mat-card>

</div>  