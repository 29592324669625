<mat-card>
    <h1 style="margin-bottom: 40px;">Moje obavijesti</h1>
    <mat-form-field appearance="outline" color="accent" style="position: absolute; top: 20px; right: 20px;">
        <mat-label>Pretraži obavijesti...</mat-label>
        <input matInput type="text" [(ngModel)]="filter">
        <button matSuffix mat-icon-button color="accent">
            <mat-icon>search</mat-icon>
          </button>
    </mat-form-field>

    <p *ngIf="!$auth.notifications.length">Nemate ni jednu obavijest</p>

    <div class="notification" *ngFor="let notification of $auth.notifications | filter:filter:'text'" [ngClass]="{'notification-seen':notification.seen}" (click)="open(notification)">
        <div class="notification-text">{{notification.text}}</div>
        <div class="notification-time">{{notification.time.toDate() | date:'short'}}</div>
        <button class="notification-button" mat-raised-button color="accent"><mat-icon>play_arrow</mat-icon></button>
    </div>

</mat-card>