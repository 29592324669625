<mat-card>
    <div style="display: flex; flex-direction: row; margin-bottom: 20px;" class="resp">
        <div class="blue"></div>
        <div class="color-name">Predavanja</div>
        <div class="orange"></div>
        <div class="color-name">Radionice</div>
        <div class="yellow"></div>
        <div class="color-name">Općenito</div>
        <div class="green"></div>
        <div class="color-name">Natjecanje</div>
    </div>
    
    <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px;">
        <button mat-raised-button color="accent" mwlCalendarPreviousView [view]="'week'"
        [(viewDate)]="viewDate"><mat-icon>chevron_left</mat-icon></button>
        <div class="rspfix" style="text-align: center; font-weight: bold; font-size: 20px; margin-left: 40px; margin-right: 40px;">{{ viewDate | calendarDate:'weekViewTitle':'hr' }}</div>
        <button mat-raised-button color="accent" mwlCalendarNextView [view]="'week'"
        [(viewDate)]="viewDate"><mat-icon>chevron_right</mat-icon></button>
    </div>
    <mwl-calendar-week-view 
    [viewDate]="viewDate" 
    [events]="calendarEvents"
    [locale]="'hr'"
    [weekStartsOn]="2"
    [dayStartHour]="8"
    [dayEndHour]="20"
    (eventClicked)="link($event.event)"
    >
    
    </mwl-calendar-week-view>
</mat-card>