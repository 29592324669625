import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProgrammesComponent } from './programmes/programmes.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { ClassroomsComponent } from './classrooms/classrooms.component';
import { ParticipantsComponent } from './participants/participants.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyNotificationsComponent } from './my-notifications/my-notifications.component';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
// import { MyAttendanceComponent } from './my-attendance/my-attendance.component';
import { CalendarComponent } from './calendar/calendar.component';
// import { ProgrammeComponent } from './programme/programme.component';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SubjectComponent } from './subject/subject.component';
import { AboutComponent } from './about/about.component';
import { AboutusComponent } from './aboutus/aboutus.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'app', component: NavigationComponent, canActivate: [AngularFireAuthGuard], children: [
    { path: 'projects', component: ProjectsComponent },
    { path: 'about', component: AboutComponent },
    { path: 'aboutus', component: AboutusComponent },
    { path: 'programmes/:id', component: ProgrammesComponent },
    { path: 'subjects/:id', component: SubjectsComponent },
    { path: 'classrooms/:id', component: ClassroomsComponent },
    { path: 'participants/:id', component: ParticipantsComponent },
    // { path: 'programme/:id', component: ProgrammeComponent },
    // { path: 'subjects/:id', component: SubjectsComponent },
    { path: 'subject/:projectId/:subjectId', component: SubjectComponent },
    { path: 'calendar/:projectId', component: CalendarComponent },
    { path: 'calendar/:subjectId/:projectId', component: CalendarComponent },
    { path: 'my-calendar', component: MyCalendarComponent },
    // { path: 'my-attendance', component: MyAttendanceComponent },
    { path: 'my-account', component: MyAccountComponent },
    { path: 'my-notifications', component: MyNotificationsComponent },
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
