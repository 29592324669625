import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import { Event } from 'models';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-my-calendar',
  templateUrl: './my-calendar.component.html',
  styleUrls: ['./my-calendar.component.css']
})
export class MyCalendarComponent implements OnInit, OnDestroy {
  calendarEvents : CalendarEvent[] = [];
  viewDate: Date = new Date();
  events: Event[] = [];

  constructor(
    public $database : DatabaseService,
    public $auth : AuthService,
    public $router : Router
  ){
    this.viewDate.setDate(this.viewDate.getDate() - (this.viewDate.getDay() + 6) % 7);
  }

  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }

  ngOnInit(): void {
    this.subs.push(this.$auth.loaded.subscribe((l) => {
      if(!l) return;
      console.log(this.$auth.participant.getValue())
      let mySubjects = this.$database.subjects.getValue().filter(s => s.participantsIds.includes(this.$auth.participant.getValue().id)).map(s => s.id)
      this.subs.push(this.$database.events.subscribe(events => {
        this.events = events.filter(e => e.subjectId == 'general' || mySubjects.includes(e.subjectId))
        if(this.$auth.participant.getValue().role == 'admin') this.events = events;
        this.calendarEvents = this.events.map(this.$database.eventToCalendarEvent)
        console.log(this.calendarEvents)
      }))
    }))

    
  }

  link(e){
    let event = this.$database.events.getValue().find(ev => ev.id == e.id)
    console.log(event)
    this.$auth.selectedSubject.next(this.$database.subjects.getValue().find(s => s.id == event.subjectId))
    this.$auth.selectedProject.next(this.$database.projects.getValue().find(s => s.id == event.projectId))
    setTimeout(() => {
      if(event.subjectId) this.$router.navigate(['app/subject',event.projectId,event.subjectId])
    }, 300);
  }

}
