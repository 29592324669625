<mat-card *ngIf="participant" style="max-width: 800px;">
    <div style="display: grid; grid-template-columns: 6fr 2fr; grid-column-gap: 40px;">
        <div class="participant-info" >
            <h1 style="margin-bottom: 4px;">{{participant.name}}</h1>
            <h3 style="margin-bottom: 20px;">{{participant.title}}</h3>
            <h3>Obrazovanje:</h3>
            <p style="margin-bottom: 20px;">{{participant.education}}</p>
            <h3>Osobni opis:</h3>
            <p style="margin-bottom: 20px;">{{participant.description}}</p>
            <h3 style="margin-bottom: 10px;">Uključen u predmete:</h3>
            <p *ngFor="let s of subjects"><mat-icon>history_edu</mat-icon> {{s.name}}</p>
        </div>
        <div class="participant-contact" >
            <img style="margin-bottom: 20px;" [src]="participant.image">
            <h3><mat-icon>location_on</mat-icon> {{participant.contactLocation}}</h3>
            <h3><mat-icon>email</mat-icon> {{participant.contactEmail}}</h3>
            <h3><mat-icon>call</mat-icon> {{participant.contactPhone}}</h3>
        </div>
        <div style="grid-column: 1 / span 2; margin-top: 20px; display: flex; align-items: flex-end; justify-content: flex-end;">
            <button mat-raised-button color="accent" style="height: 48px;" (click)="resetPassword()">RESETIRAJ LOZINKU</button>
            <button class="short-card-button" mat-raised-button color="accent" (click)="edit(participant)"><mat-icon>edit</mat-icon></button>
        </div>
    </div>
</mat-card>