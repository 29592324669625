import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { Classroom, Event } from 'models';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth.service';
import { DatabaseService } from 'src/app/database.service';
import { LoaderService } from 'src/app/loader.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $database : DatabaseService,
    public $loader : LoaderService,
    public $auth : AuthService
  ) {
    if(data.event){
      this.event = data.event;
      //@ts-ignore
      this.startDate = moment(this.event.start.toDate())
      //@ts-ignore
      this.startTime = moment(this.event.start.toDate()).format('HH:m')
      //@ts-ignore
      this.endDate = moment(this.event.end.toDate())
      //@ts-ignore
      this.endTime = moment(this.event.end.toDate()).format('HH:m')
      this.exists = true;
    } else {
      //@ts-ignore
      this.startDate = moment(data.date? data.date: new Date())
      //@ts-ignore
      this.startTime = moment(data.date? data.date:new Date()).format('HH:m')
      //@ts-ignore
      this.endDate = moment(data.date? data.date:new Date())
      //@ts-ignore
      this.endTime = moment(data.date? data.date:new Date()).format('HH:m')
      this.event = {
        id: Guid.create().toString(),
        name: "",
        description: "",
        start: new Date(),
        end: new Date(),
        allday: false,
        type: 'lecture',
        virtual: true,
        participantsIds: []
      }
      this.exists = false;
    }

  }

  ngOnInit(): void {
    // console.log(this.$data.project)
    // this.classrooms = this.$data.classrooms.filter(c => c.projectId == this.$data.project!.id)

  }

  public event : Event;
  public exists = false;

  public classrooms : Classroom[] = []

  public startDate;
  public startTime;
  public endDate;
  public endTime;

  public save(){
    console.log(this.startTime)
    let start = new Date(this.startDate.format('YYYY MM DD') + ' ' + this.startTime)
    let end = new Date(this.endDate.format('YYYY MM DD') + ' ' + this.endTime)

    if(start > end){
      this.$loader.toast('Vrijeme početka ne može biti prije vremena završetka!')
      return
    }

    this.$loader.open();
    if(this.exists){
      this.$database.updateEvent(this.event.id, this.event.name, this.event.description, start, end, this.event.type, this.event.classroomId)
      .then(() => {
        this.$loader.close('Događaj uspješno ažuriran!')
      })
      this.dialogRef.close();
    } else {
      this.$database.createEvent(this.event.name, this.event.description, start, end, this.event.type, this.event.classroomId, this.data.subjectId ? this.data.subjectId : 'general', this.data.projectId)
      .then(() => {
        this.$loader.close('Događaj uspješno stvoren!')
      })
      this.dialogRef.close();
    }
  }

  public delete(){
    if(this.exists){
      this.$loader.open()
      this.$database.deleteEvent(this.event.id).then(() => {
        this.$loader.close('Događaj uspješno obrisan!')
        this.dialogRef.close();
      })
    }
    this.dialogRef.close();
    
  }

  public log(event : any){
    console.log(event)
  }
}
