<form class="container" #myForm="ngForm">
    <mat-form-field appearance="outline">
        <mat-label>Naziv predmeta</mat-label>
        <input matInput [(ngModel)]="subject.name" type="text" name="name" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Kratki opis</mat-label>
        <input matInput [(ngModel)]="subject.description" type="text" name="description" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Naslovna slika</mat-label>
      <input matInput type="text" name="image" required [(ngModel)]="subject.image" disabled (click)="fileUpload.click()">
      <button mat-mini-fab matSuffix color="accent" (click)="fileUpload.click()"><mat-icon>attachment</mat-icon></button>
      <input type="file" class="file-input" style="display: none;"
     (change)="onFileSelected($event)" #fileUpload>
  </mat-form-field>
    <mat-label>Pripadajući program</mat-label>
    <mat-chip-list style="margin-bottom: 20px;">
        <mat-chip color="accent" [selected]="subject.programmeId == programme.id" *ngFor="let programme of $database.programmes | async | project" (click)="subject.programmeId = programme.id">{{programme.name}}</mat-chip>
      </mat-chip-list>

      <mat-form-field appearance="outline">
        <mat-label>Predavaonica</mat-label>
        <mat-select [(ngModel)]="subject.classroomId"  name="classroom" required>
          <mat-option *ngFor="let classroom of $database.classrooms | async | project" [value]="classroom.id">
            {{classroom.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Oglasna ploča</mat-label>
        <textarea matInput [(ngModel)]="subject.board" type="text" name="board" ></textarea>
    </mat-form-field>
    <button mat-raised-button color="accent"  style="margin-top: 40px" (click)="save()">Spremi promjene</button>
  </form>
