import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { LoaderService } from '../loader.service';
import { MatDialogRef } from '@angular/material/dialog'


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  public email;
  public school;
  public name;
  public message;

  constructor(
    public $loader : LoaderService,
    public $functions: AngularFireFunctions,
    public dialogRef: MatDialogRef<ContactComponent>,
){}

  public submit(){
    if(!this.email || !this.school || !this.name || !this.message) {
      this.$loader.toast('Sva polja su obavezna!')
      return
    }
    else {
      this.$loader.open();
      this.$functions.httpsCallable('sendEmail')({
        recipient: 'daria.mitar@ipemed.hr',
        subject: '[Educa] Kontakt putem login obrasca',
        message: `Nova osoba poslala je upit putem kontakt obrasca na login stranici Educa platforme:\n\nIme i prezime: ${this.name}\nEmail: ${this.email}\nInstitucija: ${this.school}\nPoruka: ${this.message}`
      }).toPromise().then(() => {
        this.dialogRef.close();
        this.$loader.close("tvoja poruka je uspješno poslana!")
      })
    }
  }
}
