import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderComponent } from './loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    public $dialog : MatDialog,
    public $snack : MatSnackBar
  ) { 

  }

  public loader : MatDialogRef<LoaderComponent> | undefined;
  public timeout 

  public open(){
    if(!this.loader)
    this.loader = this.$dialog.open(LoaderComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'rounded'
    })
    if(this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.loader.close();
      this.toast('Neuspjeh! Dogodila se greška!')
    }, 10000);
  }

  public close(text = undefined){
    if(this.loader){
      this.loader.close();
      if(text) this.toast(text)
      this.loader = undefined;
    }
    if(this.timeout) clearTimeout(this.timeout)
  }

  public toast(text){
    this.$snack.open(text, undefined, {
      'duration' : 3000,
    })
  }
}
