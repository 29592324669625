import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { Programme } from 'models';
import { DatabaseService } from 'src/app/database.service';
import { LoaderService } from 'src/app/loader.service';

@Component({
  selector: 'app-programmes-edit',
  templateUrl: './programmes-edit.component.html',
  styleUrls: ['./programmes-edit.component.css']
})
export class ProgrammesEditComponent {
  constructor(
    public dialogRef: MatDialogRef<ProgrammesEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $database: DatabaseService,
    public $loader : LoaderService
  ) {
    if (data.programme) {
      this.programme = data.programme;
      this.exists = true;
    } else {
      this.programme = {
        id: Guid.create().toString(),
        name: "Naziv..",
        image: "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: "Opis..",
        projectId: data.projectId,
        subjectsIds: [],
        participantsIds: [],
        messagesIds: []
      }
      this.exists = false;
    }
  }

  public programme: Programme;
  public exists = false;

  public save() {
    this.$loader.open()
    if (this.exists) {
      this.$database.updateProgramme(this.programme.id, this.programme.name, this.programme.image, this.programme.description)
      .then(() => {
        this.$loader.close('Program uspješno ažuriran!')
      })
      this.dialogRef.close();
    } else {
      this.$database.createProgramme(this.programme.name, this.programme.image, this.programme.description, this.programme.projectId)
      .then(() => {
        this.$loader.close('Program uspješno stvoren!')
      })
      this.dialogRef.close();
    }
  }
}
