import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Event, Subject } from 'models';

@Component({
  selector: 'app-subject-calendar',
  templateUrl: './subject-calendar.component.html',
  styleUrls: ['./subject-calendar.component.css']
})
export class SubjectCalendarComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SubjectCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    this.subject = data.subject
  }

  ngOnInit(): void {
    // this.events = this.$data.events.filter(d => d.subjectId == this.subject!.id);
  }

  public subject ?: Subject;
  public events : Event[] = []
}
