import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Participant, Project, Subject } from 'models';
import { Subscription } from 'rxjs';
import { AreyousureComponent } from '../areyousure/areyousure.component';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';
import { SubjectsEditComponent } from './subjects-edit/subjects-edit.component';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css']
})
export class SubjectsComponent implements OnDestroy{
  constructor(
    public $database : DatabaseService,
    public $router : Router,
    public $dialog: MatDialog,
    public $auth : AuthService
  ){

  }

  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }

  async ngOnInit() {
    console.log("inited")

    this.$auth.loaded.subscribe(async loaded => {
      if(!loaded) return;

      await new Promise((resolve, reject) => {
        this.subs.push(this.$auth.participant.subscribe(participant => {
          this.participant = participant
          if(this.participant) resolve(true);
        }))
      }) 
      
      await new Promise((resolve, reject) => {
        this.subs.push(this.$auth.selectedProject.subscribe(project => {
          this.project = project
          console.log(this.project)
          if(this.project) resolve(true);
        }))
      }) 
      
      this.subs.push(this.$database.subjects.subscribe(subjects => {
        this.subjects = subjects.filter(s => s.projectId == this.project?.id)
        this.subjects.forEach(s => {
          s.assetsIds = this.$database.assets.getValue().filter(a => a.subjectId == s.id).map(a => a.id)
          s.EventsIds = this.$database.events.getValue().filter(a => a.subjectId == s.id).map(a => a.id)
        })
      }))
    })

  }

  public participant : Participant | undefined;
  public project : Project | undefined;
  public subjects : Subject[] = []

  public edit(subject : Subject){
    this.$dialog.open(SubjectsEditComponent, {
      data: {subject},
      width: '600px'
    })
  }

  public create(){
    this.$dialog.open(SubjectsEditComponent, {
      data: {subject : false, projectId: this.project?.id},
      width: '600px'
    })
  }

  public delete(subject : Subject){
    this.$dialog.open(AreyousureComponent, {
      width: '300px'
    }).componentInstance.confirmed.subscribe(() => {
      this.$database.deleteSubject(subject)
    })
    
  }

  public details(subject : Subject){
    this.$auth.selectedSubject.next(subject)
    this.$router.navigate(['app/subject',this.project?.id,subject.id])
  }
}
