<mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Ime i prezime</mat-label>
    <input matInput type="text" [(ngModel)]="name"/>
</mat-form-field>
<mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Email</mat-label>
    <input matInput type="email" [(ngModel)]="email"/>
</mat-form-field>
<mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Škola / Institucija</mat-label>
    <input matInput type="email" [(ngModel)]="school"/>
</mat-form-field>
<mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Tvoja poruka</mat-label>
    <textarea matInput type="text" [(ngModel)]="message"></textarea>
</mat-form-field>
<button mat-raised-button color="primary" style="width: 100%; margin-top: 20px;" (click)="submit">Pošalji</button>