import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Participant, Subject } from 'models';
import { DatabaseService } from 'src/app/database.service';
import { LoaderService } from 'src/app/loader.service';
@Component({
  selector: 'app-subject-pastevents',
  templateUrl: './subject-pastevents.component.html',
  styleUrls: ['./subject-pastevents.component.css']
})
export class SubjectPasteventsComponent {
  constructor(
    public dialogRef: MatDialogRef<SubjectPasteventsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $database : DatabaseService,
    public $loader : LoaderService
  ){
    this.events = this.$database.events.getValue().filter(e => 
      e.subjectId == data.subjectId &&
      e.start.toDate() < new Date()
    )
  }

  public events;
  public now = new Date()

  link(event){
    
  }

  close(){
    this.dialogRef.close()
  }
}
