<app-banner></app-banner>
<div class="programmes">
    <mat-card class="card" *ngFor="let  programme of  programmes">
            <img mat-card-image class="card-image" [src]=" programme.image" alt="">
                <h3 class="card-title">{{ programme.name}}</h3>
                <p>{{ programme.description}}</p>
                <mat-divider></mat-divider>
                <div style="display: grid; grid-template-columns: auto 1fr; padding-top: 12px;">
                    <mat-icon>history_edu</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{ programme.subjectsIds.length}} Predmeta</p>
                    <!-- <mat-icon>people</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{ programme.participantsIds.length}} Sudionika</p> -->
                </div>
                <mat-divider></mat-divider>
            <div class="card-buttons" *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'">
                <button class="short-card-button" (click)="edit( programme)" mat-raised-button color="accent"><mat-icon>edit</mat-icon></button>
                <button class="short-card-button" (click)="delete( programme)" mat-raised-button color="accent"><mat-icon>delete</mat-icon></button>
            </div>
    </mat-card>
    <button mat-raised-button color="accent" style="height: 72px; width: 72px;" (click)="create()" *ngIf="($auth.participant | async).role == 'organizer' || ($auth.participant | async).role == 'admin'"><mat-icon style="scale: 3;">add</mat-icon></button>
</div>
