<div class="container">
    <mat-form-field appearance="outline">
        <mat-label>Naziv programa</mat-label>
        <input matInput [(ngModel)]="programme.name" type="text" />
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Kratki opis</mat-label>
        <input matInput [(ngModel)]="programme.description" type="text" />
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Naslovna slika</mat-label>
        <input matInput [(ngModel)]="programme.image" type="text" />
    </mat-form-field>
    <button mat-raised-button color="accent"  style="margin-top: 40px" (click)="save()">Spremi promjene</button>
</div>
