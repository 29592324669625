<div class="background" [ngClass]="{'loaded' : true}" (window:keypress)="enter($event)">
    <mat-card class="card" [ngClass]="{'loaded' : loaded, 'loaded-move' : loaded}">
        <div class="icon"></div>
        <div class="title"></div>

        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" [(ngModel)]="email"/>

        </mat-form-field>

        <mat-form-field appearance="outline" style="margin-top: 12px;" >
            <mat-label>Password</mat-label>
            <input matInput type="password" [(ngModel)]="password" (keyup.enter)="login()"/>
            
        </mat-form-field>

        <mat-error style="text-align: center;" *ngIf="!valid">Neispravan email/password</mat-error>

        <button mat-raised-button color="primary" style="margin-top: 20px; display: flex; justify-content: center; height: 42px; align-items: center; width: 100%;" (click)="login()" >LOGIN</button>
        
        <p style="text-align: center; margin-top: 10px;">Nemaš profil? <span><a (click)="contact()">Kontaktiraj nas!</a></span><p>
    </mat-card>
    <div class="anim" [ngClass]="{'loaded' : loaded, 'loaded-move' : loaded}">
            <!-- <div class="vrh"></div> -->
        <div style="padding: 8px; border-radius: 8px; background-color: white; box-shadow: 0px 4px 8px #00000050; margin: 8px;">
            <div class="lenta" ></div>
        </div>
        <!-- <div style="padding: 8px; border-radius: 8px; background-color: white;">
            <div class="nrz"></div>
        </div> -->
            
    </div>
      
</div>