import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Notification, Participant, Project, Subject } from 'models';
import { BehaviorSubject } from 'rxjs'
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public $auth: AngularFireAuth,
    public $firestore : AngularFirestore,
    public $router : Router,
    public $route : Location,
    public $zone : NgZone,
    public $database : DatabaseService
  ) {
    

    this.$database.load().then(() => {
      let url = $route.path()
      console.log(url.includes('subjects') )
      if(url.includes('participants') || url.includes('programmes') || url.includes('classrooms') || url.includes('subjects') || url.includes('calendar')){
          this.selectedProject.next(this.$database.projects.getValue().find(p => p.id == url.split('/').at(-1)))
          console.log("nexting")
      }
      if(url.includes('participants')){
        this.selectedProject.next(this.$database.projects.getValue().find(p => p.id == url.split('/').at(-1).split('?')[0]))
        console.log("tu")
      }
      if(url.includes('subject') ){
          this.selectedProject.next(this.$database.projects.getValue().find(p => p.id == $router.url.split('/').at(-2)))
          this.selectedSubject.next(this.$database.subjects.getValue().find(p => p.id == $router.url.split('/').at(-1)))
      }

      if(url.includes('subjects'))
        this.selectedProject.next(this.$database.projects.getValue().find(p => p.id == url.split('/').at(-1)))

     

      $auth.authState.subscribe(user => {
        if(user) {
          this.auth.next(user)
          this.$database.$firestore.doc(`participants/${user.uid}`).update({lastLogIn: new Date(), status: 'online'})
          this.$database.participants.subscribe((res) =>{
            this.participant.next(res.find(p => p.id == user.uid))
          })
          this.$database.notifications.subscribe(notifs => {
            this.notifications = notifs.filter(n => n.participantId == user.uid);
          })
        }
        this.loaded.next(true)
      })
      
    })        
  }

  public loaded = new BehaviorSubject<boolean>(false);

  public auth = new BehaviorSubject<any | undefined>(undefined);
  public participant = new BehaviorSubject<Participant | undefined>(undefined);
  public selectedProject = new BehaviorSubject<Project | undefined>(undefined);
  public selectedSubject = new BehaviorSubject<Subject | undefined>(undefined);
  public notifications : Notification[] = [];

  public login(email : string, password : string){
    return new Promise((resolve, reject) => {
      this.$auth.signInWithEmailAndPassword(email, password).then((auth) =>{
        this.auth.next(auth.user)
        this.$firestore.doc(`participants/${auth.user?.uid}`).update({lastActive: new Date(new Date().getTime() + 30000)})
        this.$firestore.doc(`participants/${auth.user?.uid}`).valueChanges().subscribe((res) =>{
          this.participant.next(res as Participant)
          resolve((res as Participant).name)
        })
        this.$router.navigate(['app/projects'])
      }).catch(reject)
    })
  }

  public resetPassword(email){
    return this.$auth.sendPasswordResetEmail(email)
  }

  public logout(){
    this.$database.$firestore.doc(`participants/${this.participant.getValue().id}`).update({status: 'offline'})
    this.$auth.signOut();
  }
}
