<div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%; min-height: 50vh;">
    <div>
        <h3>Razgovor s {{recipient.name}}:</h3>
        
    <div style="display: flex; flex-direction: column; margin-bottom: 20px;">
        <div class="message" *ngFor="let message of messages"  [ngClass]="{'message-mine' : message.participantId == $auth.participant.getValue().id}">
            <div class="message-image"><img [src]="message.participant!.image"><div class="status" [ngClass]="{'online':participant.status == 'online','away':participant.status == 'away'}"></div></div>
            <div class="message-name">{{message.participant!.name}}</div>
            <div class="message-title">{{message.participant!.title}}</div>
            <div style="grid-row: 1 / span 3;"> <div class="message-text"><span class="message-time">{{message.time.toDate().toLocaleString()}}<br></span>{{message.text}}<mat-icon class="message-read" *ngIf="message.seen">check</mat-icon></div></div>
        
        </div>
    </div>
    </div>
    


    <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Unesi poruku...</mat-label>
        <input matInput type="text" [(ngModel)]="text" (keyup.enter)="sendMessage()"/>
        <mat-icon matSuffix color="accent" (click)="sendMessage()">send</mat-icon>
    </mat-form-field>
</div>
