<div class="container">
  <h3 *ngIf="($auth.selectedSubject | async)">{{($auth.selectedSubject | async).name}}</h3>
    <mat-form-field appearance="outline" style="margin-bottom: 10px;">
        <mat-label>Naziv</mat-label>
        <input matInput [(ngModel)]="event.name" type="text" />
    </mat-form-field>
    <mat-form-field appearance="outline" style="margin-bottom: 10px;">
        <mat-label>Opis</mat-label>
        <input matInput [(ngModel)]="event.description" type="text" />
    </mat-form-field>
    <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 12px; grid-row-gap: 10px; margin-bottom: 10px;">
      <mat-form-field appearance="outline">
        <mat-label>Početak (datum):</mat-label>
        <input matInput [(ngModel)]="startDate" [matDatepicker]="startDatePicker"/>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" (click)="undefined">
      <mat-label>Početak (vrijeme):</mat-label>
      <input matInput [(ngModel)]="startTime" [format]="24"[disableClick]="true"  [ngxMatTimepicker]="startTimePicker" />
      <ngx-mat-timepicker-toggle matSuffix [for]="startTimePicker"></ngx-mat-timepicker-toggle>
      <ngx-mat-timepicker  #startTimePicker></ngx-mat-timepicker>  
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Kraj (datum):</mat-label>
    <input matInput [(ngModel)]="endDate" [matDatepicker]="endDatePicker"/>
    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-label>Kraj (vrijeme):</mat-label>
  <input matInput [(ngModel)]="endTime" [format]="24"[disableClick]="true"[disableClick]="true"  [ngxMatTimepicker]="endTimePicker" />
  <ngx-mat-timepicker-toggle matSuffix [for]="endTimePicker"></ngx-mat-timepicker-toggle>
  <ngx-mat-timepicker #endTimePicker></ngx-mat-timepicker>  
</mat-form-field>
    </div>
    

    <mat-chip-list style="margin-top: 0px; margin-bottom: 12px;">
        <mat-chip color="accent" [selected]="event.type == 'lecture'" (click)="event.type = 'lecture'">Predavanje</mat-chip>
        <mat-chip color="accent" [selected]="event.type  == 'workshop'" (click)="event.type = 'workshop'">Radionica</mat-chip>
        <mat-chip color="accent" [selected]="event.type  == 'competition'" (click)="event.type = 'competition'">Natjecanje</mat-chip>
        <mat-chip color="accent" [selected]="event.type  == 'general'" (click)="event.type = 'general'">Generalno</mat-chip>
      </mat-chip-list>
      <mat-form-field appearance="outline">
        <mat-label>Predavaonica</mat-label>
        <mat-select [(ngModel)]="event.classroomId">
          <mat-option *ngFor="let classroom of $database.classrooms | async | project" [value]="classroom.id">
            {{classroom.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button color="accent" style="margin-top: 40px;" [disabled]="!exists" (click)="delete()">Ukloni</button>
      <button mat-raised-button color="accent" (click)="save()">Spremi promjene</button>
</div>