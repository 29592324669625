<app-banner></app-banner>
<div style="display: grid; grid-template-columns: 2fr 3fr; grid-column-gap: 20px;">
    <div>
        <mat-card style="margin-bottom: 20px;">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Pretraži</mat-label>
                <input matInput [(ngModel)]="filter" placeholder="Naziv" type="text">
            </mat-form-field>
        </mat-card>
        <button class="add-person-button" *ngIf="($auth.participant | async).role == 'admin' || ($auth.participant | async).role == 'organizer'" (click)="create()" mat-raised-button color="accent"><span>DODAJ UČIONICU</span><mat-icon>add</mat-icon></button>
        <mat-card  class="participant-card" *ngFor="let c of classrooms | filter:filter:'name'" (click)="selectedClassroom = c; filter = ''">
            <div class="participant-card-image"><img [src]="c.image"></div>
            <div class="participant-card-name">{{c.name}}</div>
            <div class="participant-card-title">{{c.type == 'virtual' ? 'Virtualna predavaonica' : 'Fizička predavaonica'}}</div>
            <!-- <div class="participant-card-link">{{p.location}}</div> -->
            <!-- <div class="participant-card-chat">
                <button mat-raised-button color="accent" class="short-card-button"><mat-icon>chat</mat-icon></button>
            </div> -->
        </mat-card>
    </div>
    <div>
        <mat-card *ngIf="selectedClassroom">
            <div style="display: grid; grid-template-columns: 4fr 2fr; grid-column-gap: 10px;">
                <div class="participant-info" >
                    <h1 style="margin-bottom: 4px">{{selectedClassroom.name}}</h1>
                    <h3 style="margin-bottom: 20px">{{selectedClassroom.type == 'virtual' ? 'Virtualna predavaonica' : 'Fizička predavaonica'}}</h3>
                    <h3>Opis učionice:</h3>
                    <p style="margin-bottom: 20px">{{selectedClassroom.description}}</p>
                    <h3 *ngIf=" selectedClassroom.subjects.length">Uključen u predmete:</h3>
                    <p *ngFor="let subject of selectedClassroom.subjects"><mat-icon>history_edu</mat-icon> {{subject.name}}</p>

                    <button *ngIf="selectedClassroom.type == 'virtual'" mat-raised-button extended (click)="openMeeting()" color="accent" style="height: 50px; text-decoration: underline;">
                        <mat-icon>meeting_room</mat-icon>
                        {{selectedClassroom.link}}
                    </button>

                    <button *ngIf="selectedClassroom.type == 'physical'" mat-raised-button extended (click)="openMeeting()" color="accent" style="height: 50px; text-decoration: underline;">
                        <mat-icon>location_on</mat-icon>
                        {{selectedClassroom.location}}
                    </button>
                   
                </div>
                <div class="participant-contact" >
                    <img [src]="selectedClassroom.image">
                    <!-- <h3><mat-icon>location_on</mat-icon> {{participant.location}}</h3>
                    <h3><mat-icon>email</mat-icon> {{participant.email}}</h3>
                    <h3><mat-icon>call</mat-icon> {{participant.phone}}</h3> -->
                </div>
                <div style="grid-column: 1 / span 2; margin-top: 20px; display: flex; align-items: flex-end; justify-content: flex-end;" *ngIf="($auth.participant | async).role == 'admin' || ($auth.participant | async).role == 'organizer'">
                    <!-- <button mat-raised-button extended color="accent" style="height: 50px;">
                        <mat-icon>calendar_month</mat-icon>
                        KALENDAR PROSTORIJE
                    </button> -->
                    <button class="short-card-button" mat-raised-button color="accent" (click)="edit(selectedClassroom)"><mat-icon>edit</mat-icon></button>
                    <button class="short-card-button" mat-raised-button color="accent" (click)="delete(selectedClassroom)"><mat-icon>delete</mat-icon></button>
                </div>
            </div>
        </mat-card>
    </div>
</div>