import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { Project, Subject, Event } from 'models';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';
import { EventComponent } from './event/event.component';
import { Subscription} from 'rxjs'

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, OnDestroy {

  constructor(
    public $dialog : MatDialog,
    public $route : ActivatedRoute,
    public $auth : AuthService,
    public $router : Router,
    public $database : DatabaseService
  ){
    this.viewDate.setDate(this.viewDate.getDate() - (this.viewDate.getDay() + 6) % 7);
    //  as Subject
  
    // if(this.subject) {
    //   this.events = this.$data.events.filter(e => e.subjectId == this.subject.id)
    //   this.calendarEvents = this.events.map(e => { return {start: e.start, end: e.end, title: e.name, color: {'primary' : this.eventToColor(e.type), 'secondary': this.eventToColor(e.type), secondaryText: '#ffffff'}}})
    // } else {
    //   this.events = this.$data.events.filter(e => e.projectId == this.project.id)
    //   this.calendarEvents = this.events.map(e => { return {start: e.start, end: e.end, title: e.name, color: {'primary' : this.eventToColor(e.type), 'secondary': this.eventToColor(e.type), secondaryText: '#ffffff'}}})
    // }
  }

  ngOnInit(): void {
    this.$database.load().then(() => {
      this.projectId = this.$route.snapshot.params['projectId']
      this.subjectId = this.$route.snapshot.params['subjectId']
      if(this.subjectId) this.$auth.selectedSubject.next(this.$database.subjects.getValue().find(s => s.id == this.subjectId))
      this.subs.push(this.$database.events.subscribe(events => {
        this.events = events.filter(e => e.projectId == this.projectId)
        this.events = this.events.filter(e => !this.subjectId || e.subjectId == this.subjectId)
        this.calendarEvents = this.events.map(this.$database.eventToCalendarEvent)
        console.log(this.calendarEvents)
      }))
    })
  }
  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }



  project : Project | undefined;
  subject : Subject | undefined;

  projectId: string;
  subjectId: string;

  events : Event[] = []
  calendarEvents : CalendarEvent[] = [];
  viewDate: Date = new Date()

  edit(e : CalendarEvent){
    let event = this.events.find(ev => ev.name == e.title)
    // console.log(event)
    // this.$dialog.open(EventComponent, {
    //   data: {event},
    //   width: '600px'
    // }).afterClosed().subscribe(() => {
    //   if(this.subject) {
    //     this.events = this.$data.events.filter(e => e.subjectId == this.subject.id)
    //     this.calendarEvents = this.events.map(e => { return {start: e.start, end: e.end, title: e.name, color: {'primary' : this.eventToColor(e.type), 'secondary': this.eventToColor(e.type), secondaryText: '#ffffff'}}})
    //   } else {
    //     this.events = this.$data.events.filter(e => e.projectId == this.project.id)
    //     this.calendarEvents = this.events.map(e => { return {start: e.start, end: e.end, title: e.name, color: {'primary' : this.eventToColor(e.type), 'secondary': this.eventToColor(e.type), secondaryText: '#ffffff'}}})
    //   }    })
    this.$dialog.open(EventComponent, {
      data: {event : event},
      width: '600px',
      maxHeight: '90vh'
    })
  }

  backToSubject(){
    this.$auth.selectedSubject.next(this.$database.subjects.getValue().find(s => s.id == this.subjectId))
    this.$auth.selectedProject.next(this.$database.projects.getValue().find(s => s.id == this.projectId))
    this.$router.navigate(['app/subject',this.projectId,this.subjectId])
  }

  create(date?){
    this.$dialog.open(EventComponent, {
      data: {event : false, subjectId: this.subjectId, projectId: this.projectId, date: date},
      width: '600px',
      maxHeight: '90vh'
    })
  }

  open(event){
    this.create(event.date)
  }
}
