import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { LoginComponent } from './login/login.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProgrammesComponent } from './programmes/programmes.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { ClassroomsComponent } from './classrooms/classrooms.component';
import { ParticipantsComponent } from './participants/participants.component';
import { CalendarComponent } from './calendar/calendar.component';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
// import { MyAttendanceComponent } from './my-attendance/my-attendance.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyNotificationsComponent } from './my-notifications/my-notifications.component';
// import { ProgrammeComponent } from './programme/programme.component';
import { SubjectComponent } from './subject/subject.component';
import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';
import { ParticipantsEditComponent } from './participants/participants-edit/participants-edit.component';
import { ProgrammesEditComponent } from './programmes/programmes-edit/programmes-edit.component';
import { ProjectsEditComponent } from './projects/projects-edit/projects-edit.component';
import { SubjectsEditComponent } from './subjects/subjects-edit/subjects-edit.component';
import { ClassroomsEditComponent } from './classrooms/classrooms-edit/classrooms-edit.component';
import { BannerComponent } from './banner/banner.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { EventComponent } from './calendar/event/event.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import hr from '@angular/common/locales/hr';
import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from "@angular/core";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { SubjectContentComponent } from './subject/subject-content/subject-content.component';
import { SubjectParticipantsComponent } from './subject/subject-participants/subject-participants.component';
import { SubjectCalendarComponent } from './subject/subject-calendar/subject-calendar.component';
import 'moment/locale/hr'
import { ProjectPipe } from './project.pipe';
import { LoaderComponent } from './loader/loader.component';
import { AreyousureComponent } from './areyousure/areyousure.component';
moment.locale('hr')

export function momentAdapterFactory() {
  
  moment.updateLocale('hr', {
    week: {
      dow: 1, // set start of week to monday instead
      doy: 0,
    },
    longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D MMMM YYYY',
      LLL : 'D MMMM YYYY HH:mm',
      LLLL : 'dddd D MMMM YYYY HH:mm'
  },
  });
  return adapterFactory(moment);
};

registerLocaleData(hr)


import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public override monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', 'hr').toUpperCase();
  }

  public override monthViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'MMMM y', locale).toUpperCase()
    .replace('SIJEČNJA', 'SIJEČANJ')
    .replace('VELJAČE', 'VELJAČA')
    .replace('OŽUJKA', 'OŽUJAK')
    .replace('TRAVNJA', 'TRAVANJ')
    .replace('LIPNJA', 'LIPANJ')
    .replace('SVIBNJA', 'SVIBANJ')
    .replace('SRPNJA', 'SRPANJ')
    .replace('KOLOVOZA', 'KOLOVOZ')
    .replace('RUJNA', 'RUJAN')
    .replace('LISTOPADA', 'LISTOPAD')
    .replace('STUDENOGA', 'STUDENI')
    .replace('PROSINCA', 'PROSINAC');
  }

  public override weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return  formatDate(date, 'EEE', locale).toUpperCase();
  }

  public override dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
  }

  public override weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyD5bUk8KU-Ju9GMU7vd_yyAdB9uY5b9hHo",
  authDomain: "educa-1a6a5.firebaseapp.com",
  projectId: "educa-1a6a5",
  storageBucket: "educa-1a6a5.appspot.com",
  messagingSenderId: "988737839537",
  appId: "1:988737839537:web:e4af5a310ca6d445275d90"
};
import { Pipe, PipeTransform } from '@angular/core';
import { AboutComponent } from './about/about.component';
import { SubjectPasteventsComponent } from './subject/subject-pastevents/subject-pastevents.component';
import { ContactComponent } from './contact/contact.component';
import { AboutusComponent } from './aboutus/aboutus.component';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(value: any, filterString: string, property: string): any[] {
    if (value.length === 0 || !filterString) {
      return value;
    }
    let filteredUsers: any[] = [];
    for (let user of value) {
      if (user[property].toLowerCase().includes(filterString.toLowerCase())) {
        filteredUsers.push(user);
      }
    }
    return filteredUsers;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    LoginComponent,
    ProjectsComponent,
    ProgrammesComponent,
    SubjectsComponent,
    ClassroomsComponent,
    ParticipantsComponent,
    CalendarComponent,
    MyCalendarComponent,
    // MyAttendanceComponent,
    MyAccountComponent,
    MyNotificationsComponent,
    // ProgrammeComponent,
    SubjectComponent,
    ParticipantsEditComponent,
    ProgrammesEditComponent,
    ProjectsEditComponent,
    SubjectsEditComponent,
    ClassroomsEditComponent,
    BannerComponent,
    EventComponent,
    SubjectContentComponent,
    SubjectParticipantsComponent,
    SubjectCalendarComponent,
    ProjectPipe,
    LoaderComponent,
    AreyousureComponent,
    MyNotificationsComponent,
    ChatComponent,
    FilterPipe,
    AboutComponent,
    SubjectPasteventsComponent,
    ContactComponent,
    AboutusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory}),
  ],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
