import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Participant, Programme, Project } from 'models';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';

import { ProgrammesEditComponent } from './programmes-edit/programmes-edit.component';


@Component({
  selector: 'app-programmes',
  templateUrl: './programmes.component.html',
  styleUrls: ['./programmes.component.css']
})
export class ProgrammesComponent implements OnInit, OnDestroy {
  constructor(
    public $database : DatabaseService,
    public $router : Router,
    public $dialog: MatDialog,
    public $auth : AuthService
  ){

  }

  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }

  async ngOnInit() {
    console.log("inited")
    this.$auth.selectedSubject.next(undefined)

    await new Promise((resolve, reject) => {
      this.subs.push(this.$auth.participant.subscribe(participant => {
        this.participant = participant
        if(this.participant) resolve(true);
      }))
    }) 
    
    await new Promise((resolve, reject) => {
      this.subs.push(this.$auth.selectedProject.subscribe(project => {
        this.project = project
        if(this.project) resolve(true);
      }))
    }) 
    
    this.subs.push(this.$database.programmes.subscribe(programmes => {
      this.programmes = programmes.filter(p => p.projectId == this.project?.id)
    }))
  }

  public participant : Participant | undefined;
  public project : Project | undefined;
  public programmes : Programme[] = []

  public edit(programme : Programme){
    this.$dialog.open(ProgrammesEditComponent, {
      data: {programme},
      width: '600px'
    })
  }

  public create(){
    this.$dialog.open(ProgrammesEditComponent, {
      data: {programme : false, projectId: this.project?.id},
      width: '600px'
    })
  }

  public delete(programme : Programme){
    this.$database.deleteProgramme(programme)
  }
}
