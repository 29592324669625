import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { LoaderService } from '../loader.service';
import { MatDialog } from '@angular/material/dialog'
import { ContactComponent } from '../contact/contact.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(
    public $router : Router,
    public $auth : AuthService,
    public $loader : LoaderService,
    public $dialog: MatDialog
  ){

  }

  loaded = false;
  ngOnInit(): void {
    this.$loader.open();
    setTimeout(() => {
      this.loaded = true;
      this.$loader.close();
    }, 1000);
  }

  public email = '';
  public password = ''

  public submitted = false;
  public valid = true;

  public login(){
    this.submitted = true;
    this.$loader.open()
    this.$auth.login(this.email, this.password).then((res) => {
      this.$loader.close();
      this.$loader.toast(`Uspješna prijava! Dobro došao ${res}!`)
    }).catch(() => {
      this.$loader.close();
      this.$loader.toast('Neuspješna prijava! Molimo vas provjerite unesene podatke.')
      this.valid = false;
    })
  }

  enter(event){
    if(event.keyCode==13)this.login()
  }

  contact(){
    this.$dialog.open(ContactComponent, {
      maxWidth: '300px',
      maxHeight: '90vh'
    })
  }
}
