import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Participant, Subject } from 'models';
import { DatabaseService } from 'src/app/database.service';
import { LoaderService } from 'src/app/loader.service';
// import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-subject-participants',
  templateUrl: './subject-participants.component.html',
  styleUrls: ['./subject-participants.component.css']
})
export class SubjectParticipantsComponent {
  constructor(
    public dialogRef: MatDialogRef<SubjectParticipantsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $database : DatabaseService,
    public $loader : LoaderService
  ) {
    this.subject = this.data.subject;
    this.oldSubject = JSON.parse(JSON.stringify(this.data.subject));
    this.$database.participants.subscribe(participants => {
      this.participants = participants.filter(p => p.projectId == this.subject.projectId).filter(p => p.role == 'participant');
      this.teachers = participants.filter(p => p.projectId == this.subject.projectId).filter(p => p.role == 'teacher');
      this.organizers = participants.filter(p => p.projectId == this.subject.projectId).filter(p => p.role == 'organizer');
      this.administrators = participants.filter(p => p.projectId == this.subject.projectId).filter(p => p.role == 'admin');
    })
  }

  public subject : Subject;
  public participants : Participant[];
  public teachers : Participant[];
  public organizers : Participant[];
  public administrators : Participant[];
  public oldSubject : Subject

  public save(){
    this.$loader.open();

    let toUpdate = [];
    this.subject.participantsIds.forEach(p => {
      if(!this.oldSubject.participantsIds.includes(p)){
        toUpdate.push(this.participants.find(part => part.id == p))
      }
    })

    console.log(toUpdate)

    this.$database.updateSubjectParticipants(this.subject.id, this.subject.participantsIds, toUpdate, this.subject).then(() => {
      this.$loader.close('Uspješno promijenjeni sudionici')
    })
    this.dialogRef.close();
  }
}
