<app-banner></app-banner>
<div style="display: grid; grid-template-columns: 2fr 3fr; grid-column-gap: 20px;" *ngIf="loaded">
    <div>
        <mat-card style="margin-bottom: 20px;">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Pretraži</mat-label>
                <input matInput [(ngModel)]="filter" placeholder="Ime i prezime..." type="text">
            </mat-form-field>
        </mat-card>
        <button class="add-person-button" *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'" (click)="create()" mat-raised-button color="accent"><span>DODAJ OSOBU</span><mat-icon>add</mat-icon></button>
        <mat-card  class="participant-card" *ngFor="let p of participants | filter:filter:'name'" (click)="select(p)">
            <div class="participant-card-image"><img [src]="p.image"><div class="status" [ngClass]="{'online':p.status == 'online', 'away': p.status == 'away'}"></div></div>
            <div class="participant-card-name">{{p.name}}</div>
            <div class="participant-card-title">{{p.title}}</div>
            <div class="participant-card-link">{{p.contactLocation}}</div>
            <div class="participant-card-chat">
                <button mat-raised-button color="accent" class="short-card-button"  (click)="openChat(p)"><mat-icon>chat</mat-icon></button>
            </div>
        </mat-card>
    </div>
    <div>
        <mat-card *ngIf="!selectedParticipant">Nije odabran nijedan sudionik...</mat-card>
        <mat-card *ngIf="selectedParticipant">
            <div style="display: grid; grid-template-columns: 4fr 2fr; grid-column-gap: 10px;">
                <div class="participant-info" >
                    <h1>{{selectedParticipant.name}}</h1>
                    <h3>{{selectedParticipant.title}}</h3>
                    <h3>Obrazovanje:</h3>
                    <p>{{selectedParticipant.education}}</p>
                    <h3>Osobni opis:</h3>
                    <p>{{selectedParticipant.description}}</p>
                    <h3 style="margin-bottom: 10px;">Uključen u predmete:</h3>
                    <p *ngFor="let s of subjects"><mat-icon>history_edu</mat-icon> {{s.name}}</p>
                </div>
                <div class="participant-contact" >
                    <img [src]="selectedParticipant.image">
                    <h3><mat-icon>location_on</mat-icon> {{selectedParticipant.contactLocation}}</h3>
                    <h3><mat-icon>email</mat-icon> {{selectedParticipant.contactEmail}}</h3>
                    <h3><mat-icon>call</mat-icon> {{selectedParticipant.contactPhone}}</h3>
                </div>
                <div style="grid-column: 1 / span 2; margin-top: 20px; display: flex; align-items: flex-end; justify-content: flex-end;" *ngIf="(($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer' && participant!.role != 'admin')">
                    <button mat-raised-button extended color="accent" style="height: 48px;" (click)="openChat()"><mat-icon>message</mat-icon></button>
                    <button class="short-card-button" mat-raised-button color="accent" (click)="edit(selectedParticipant!)"><mat-icon>edit</mat-icon></button>
                <button class="short-card-button" mat-raised-button color="accent" (click)="delete(selectedParticipant)"><mat-icon>delete</mat-icon></button>
                </div>
            </div>
        </mat-card>

    </div>
</div>