import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { Participant } from 'models';
import { AuthService } from 'src/app/auth.service';
import { DatabaseService } from 'src/app/database.service';
import { LoaderService } from 'src/app/loader.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-participants-edit',
  templateUrl: './participants-edit.component.html',
  styleUrls: ['./participants-edit.component.css']
})
export class ParticipantsEditComponent {
  constructor(
    public dialogRef: MatDialogRef<ParticipantsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $database : DatabaseService,
    public $loader : LoaderService,
    public $auth : AuthService
  ) {
    if(data.participant){
      this.participant = data.participant;
      this.exists = true;
    } else {
      this.participant = {
        id: Guid.create().toString(),
        name: "",
        admin: false,
        image: "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: "",
        title: '',
        role: 'participant',
        education: '',
        contactEmail: '',
        contactLink: '',
        contactLocation: '',
        contactPhone: '',
        projectId: data.projectId,
        email: '',
        password: ''
      }
      this.exists = false;
    }
  }

  public participant : Participant;
  public exists = false;

  
  @ViewChild('myForm') myForm: NgForm;

  public save(){

    if(!this.participant.email || !this.participant.name || !this.participant.title || !this.participant.role || !this.participant.description || !this.participant.education || !this.participant.contactEmail || !this.participant.contactLocation || !this.participant.contactPhone || !this.participant.contactLink){
      this.myForm.form.markAllAsTouched();
      this.$loader.toast('Nisu ispunjeni svi potrebni podatci!')
      return;
    }

    this.$loader.open()
    if(this.exists){
      this.$database.updateParticipant(this.participant.id, this.participant.email, this.participant.password, this.participant.name, this.participant.image, this.participant.description, this.participant.projectId, this.participant.education, this.participant.role, this.participant.title, this.participant.contactEmail, this.participant.contactEmail, this.participant.contactLocation, this.participant.contactPhone).then(() => {
        this.$loader.close('Podatci sudionika uspješno ažurirani!')
      })
      this.dialogRef.close();
    } else {
      this.$database.createParticipant(this.participant.email, this.participant.password, this.participant.name, this.participant.image, this.participant.description, this.participant.projectId, this.participant.education, this.participant.role, this.participant.title, this.participant.contactEmail, this.participant.contactLink, this.participant.contactLocation, this.participant.contactPhone).then(() => {
        this.$loader.close('Sudionik uspješno stvoren!')
      })
      this.dialogRef.close();
    }
  }

  public log(event : any){
    console.log(event)
  }

  public onFileSelected(event){
    this.$loader.open()
    const file = event.target.files[0];
    if (file) {
      this.$database.pushFileToStorage(file, file.name).then((res) => {
        this.participant.image = res as string;
        this.$loader.close('Datoteka uspješno uploadana!');
      })
    }
  }
}
