import { Component, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-areyousure',
  templateUrl: './areyousure.component.html',
  styleUrls: ['./areyousure.component.css']
})
export class AreyousureComponent {

  constructor(
    public dialogRef: MatDialogRef<AreyousureComponent>,
  ){

  }

  public confirmed = new EventEmitter();
  confirm(){
    this.confirmed.emit();
    this.dialogRef.close();
  }

  reject(){
    this.dialogRef.close();
  }
}
