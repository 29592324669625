import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { Asset, Subject } from 'models';
import { AuthService } from 'src/app/auth.service';
import { DatabaseService } from 'src/app/database.service';
import { LoaderService } from 'src/app/loader.service';

@Component({
  selector: 'app-subject-content',
  templateUrl: './subject-content.component.html',
  styleUrls: ['./subject-content.component.css']
})
export class SubjectContentComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SubjectContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $firestore: AngularFirestore,
    public $functions : AngularFireFunctions,
    public $database : DatabaseService,
    public $loader : LoaderService,
    public $auth : AuthService
  ) {
    this.subject = data.subject;
  }

  ngOnInit(): void {
    this.$database.assets.subscribe(assets => {
      this.assets = assets.filter(a => a.subjectId == this.subject.id)
    })
  }

  public subject : Subject
  public assets : Asset[] = []
  public chosenAssetType : string = 'doc';

  public add(){
    if(['doc','sheet','slide','form'].includes(this.chosenAssetType)){
      this.$loader.open();
      this.$functions.httpsCallable('createAsset')({name: 'new asset', type: this.chosenAssetType}).toPromise().then(res => {
        this.$database.createAsset('Naziv', this.chosenAssetType, res.link, res.google, this.$auth.selectedSubject.getValue().id).then(() => {
          this.$loader.close();
          this.$loader.toast('Materijal uspješno dodan!')
        })
      })
    } else {
      this.$database.createAsset('Naziv', this.chosenAssetType, '', '', this.$auth.selectedSubject.getValue().id).then(() => {
        this.$loader.close();
        this.$loader.toast('Materijal uspješno dodan!')
      })
    }
    // this.subject.assets.push({
    //   id: Guid.create().toString(),
    //   name: 'MATERIJAL',
    //   type: 'doc',
    //   link: 'https://docs.google.com/document/d/1XADGaMMMB5lVFT3BOweRt6u4oxoeDz741tHq3hEDg-w/edit?usp=sharing',
    //   time: new Date().toLocaleString(),
    // })
  }

  public save(){
    this.$loader.open();
    Promise.all(this.assets.map(a => this.$database.updateAsset(a.id,a.name,a.link))).then(() => {
      this.$loader.close('Materijali updateani!')
    })
    this.dialogRef.close();
  }

  public delete(asset){
    this.$loader.open();
    this.$database.deleteAsset(asset.id).then(() => {
      this.assets.splice(this.assets.indexOf(asset),1)
      this.$loader.close("Materijal uklonjen!")
    })
  }

  
  public onFileSelected(event, asset){
    this.$loader.open()
    const file = event.target.files[0];
    if (file) {
      this.$database.pushFileToStorage(file, file.name).then((res) => {
        asset.link = res as string;
        this.$loader.close();
      })
    }
  }
}
