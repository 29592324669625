
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Message, Participant, Project, Subject } from 'models';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { ChatComponent } from '../chat/chat.component';
import { DatabaseService } from '../database.service';
import { LoaderService } from '../loader.service';
import { ParticipantsEditComponent } from './participants-edit/participants-edit.component';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.css']
})
export class ParticipantsComponent implements OnInit, OnDestroy {
  constructor(
    public $database : DatabaseService,
    public $dialog : MatDialog,
    public $auth : AuthService,
    public $loader : LoaderService,
    public $route : ActivatedRoute
  ){
    
  }

  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }

  async ngOnInit() {

    let project = this.$route.snapshot.params['id']
    console.log(project)
    this.$auth.selectedProject.next(this.$database.projects.getValue().find(p => p.id == project))

    await new Promise((resolve, reject) => {
      this.subs.push(this.$auth.participant.subscribe(participant => {
        this.participant = participant
        if(this.participant) resolve(true);
      }))
    }) 
    
    await new Promise((resolve, reject) => {
      this.subs.push(this.$auth.selectedProject.subscribe(project => {
        this.project = project
        if(this.project) resolve(true);
      }))
    }) 

    console.log("here")
    this.subs.push(this.$database.participants.subscribe(participants => {
      this.participants = participants.filter(p => p.projectId == this.project?.id || p.role == 'admin')
      this.loaded = true;  
    }))

   
    setTimeout(() => {
      let chosenParticipant = this.$route.snapshot.queryParams['participantId'];
      if(chosenParticipant) {
        this.select(this.participants.find(p => p.id == chosenParticipant))
      }
      
    }, 500);

  }

  public loaded = false;
  public project : Project | undefined;
  public participants : Participant[] = [];
  public participant : Participant | undefined;
  public selectedParticipant : Participant | undefined;
  public filter = "";
  public messageText = "";

  edit(participant : Participant){
    this.$dialog.open(ParticipantsEditComponent, {
      data: {participant : this.selectedParticipant, projectId:this.project?.id},
      width: '600px',
      maxHeight: '90vh'
    })
  }

  create(){
    this.$dialog.open(ParticipantsEditComponent, {
      data: {participant : false, projectId:this.project?.id},
      width: '600px',
      maxHeight: '90vh'
    })
  }

  delete(participant){
    this.$database.deleteParticipant(participant)
    this.selectedParticipant = undefined
  }

  select(participant){
    this.selectedParticipant = participant;
    this.filter = ""
    this.$database.messages.subscribe(mes => {
      this.messages = mes.filter(m => (m.recipientId == this.selectedParticipant.id && m.participantId == this.$auth.participant.getValue().id) || (m.recipientId == this.$auth.participant.getValue().id && m.participantId == this.selectedParticipant.id)).slice(-6)
      this.subjects = this.$database.subjects.getValue().filter(s => this.participant.role == 'admin' || s.participantsIds.includes(participant.id));
    })
  }

  public subjects : Subject[] = [];

  resetPassword(){
    this.$auth.resetPassword(this.participant.email)
  }

  sendMessage(){
    this.$loader.open()
    this.$database.createMessage(this.messageText, this.$auth.participant.getValue(), this.selectedParticipant.id).then(() => {
      this.$loader.close();
      this.messageText = '';
      this.$loader.toast('Poruka uspješno poslana!')
    })
  }

  public messages: Message[]

  openChat(p = undefined){
    this.$dialog.open(ChatComponent, {
      data: {
        participantId: this.participant.id,
        recipientId : p?p.id:this.selectedParticipant.id
      }, width: '100%', maxWidth: '60vw', maxHeight: '90vh', minHeight: '50vh'
    })
  }
}
