import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from './auth.service';

@Pipe({
  name: 'project',
  pure: false
})
export class ProjectPipe implements PipeTransform {

  transform(value: any): any {
    if(value && value.length){
      value = value.filter(o => o.projectId == this.projectId && o.projectId !== undefined ) 
    } 
    return value;
  }

  public projectId;
  constructor(
    public $auth : AuthService
  ) {
    this.$auth.selectedProject.subscribe(proj => {
      if(proj) this.projectId = proj.id;
    })
  }

}
