import { Component } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent {
  constructor(
    public $auth : AuthService
  ){

  }
}
