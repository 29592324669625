import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Participant, Project } from 'models';
import { Subscriber, Subscription } from 'rxjs';
import { AreyousureComponent } from '../areyousure/areyousure.component';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';
import { LoaderService } from '../loader.service';
import { ProjectsEditComponent } from './projects-edit/projects-edit.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  constructor(
    public $database : DatabaseService,
    public $router : Router,
    public $dialog: MatDialog,
    public $auth : AuthService,
    public $loader : LoaderService
  ){

  }

  async ngOnInit() {
    await new Promise((resolve, reject) => {
      this.subs.push(this.$auth.participant.subscribe(participant => {
        this.participant = participant
        if(this.participant) resolve(true);
      }))
    })  

    this.$auth.selectedProject.next(undefined)
    
    this.subs.push(this.$database.projects.subscribe(projects => {
      if(this.participant?.id) {
        if(this.participant.role == 'admin')
          this.projects = projects
        else 
          this.projects = projects.filter(p => p.participantsIds.includes(this.participant!.id))
      }
    }))
  }

  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }

  public projects : Project[] = [];
  public participant : Participant | undefined;

  public details(project : Project){
    this.$auth.selectedProject.next(project)
    this.$router.navigate(['app/subjects', project.id])
  }

  public edit(project : Project){
    this.$dialog.open(ProjectsEditComponent, {
      data: {project},
      width: '600px'
    })
  }

  public create(){
    this.$dialog.open(ProjectsEditComponent, {
      data: {project : false},
      width: '600px'
    })
  }

  public delete(project : Project){
    this.$dialog.open(AreyousureComponent, {
      width: '300px'
    }).componentInstance.confirmed.subscribe(() => {
      this.$database.deleteProject(project.id)
    })

  }

  text = '';
 
}
