<form class="container" #myForm="ngForm">

    <mat-form-field appearance="outline" *ngIf="!exists">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="participant.email" type="text" name="email" required/>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="!exists">
        <mat-label>Password</mat-label>
        <input matInput [(ngModel)]="participant.password" type="text" name="password" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Ime i prezime</mat-label>
        <input matInput [(ngModel)]="participant.name" type="text" name="name" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Titula</mat-label>
        <input matInput [(ngModel)]="participant.title" type="text" name="title" required/>
    </mat-form-field>
    <mat-chip-list [disabled]="($auth.participant|async).role != 'admin'">
        <mat-chip color="accent" [selected]="participant.role == 'participant'" (click)="participant.role = 'participant'">Sudionik</mat-chip>
        <mat-chip color="accent" [selected]="participant.role == 'teacher'" (click)="participant.role = 'teacher'">Predavač</mat-chip>
        <mat-chip color="accent" [selected]="participant.role == 'organizer'" (click)="participant.role = 'organizer'">Organizator</mat-chip>
        <mat-chip color="accent" [selected]="participant.role == 'admin'" (click)="participant.role = 'admin'">Administrator</mat-chip>
      </mat-chip-list>
    
    <mat-form-field appearance="outline">
        <mat-label>Kratki opis</mat-label>
        <input matInput [(ngModel)]="participant.description" type="text" name="description" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Kratki akademski životopis</mat-label>
        <input matInput [(ngModel)]="participant.education" type="text" name="education" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Naslovna slika</mat-label>
        <input matInput type="text" name="image" required [(ngModel)]="participant.image" disabled (click)="fileUpload.click()">
        <button mat-mini-fab matSuffix color="accent" (click)="fileUpload.click()"><mat-icon>attachment</mat-icon></button>
        <input type="file" class="file-input" style="display: none;"
       (change)="onFileSelected($event)" #fileUpload>
    </mat-form-field>
    
    <mat-form-field appearance="outline">
        <mat-label>Kontakt Email</mat-label>
        <input matInput [(ngModel)]="participant.contactEmail" type="text" name="contactEmail" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Kontakt Lokacija</mat-label>
        <input matInput [(ngModel)]="participant.contactLocation" type="text" name="contactLocation" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Kontakt Telefon</mat-label>
        <input matInput [(ngModel)]="participant.contactPhone" type="text" name="contactPhone" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Kontakt Link</mat-label>
        <input matInput [(ngModel)]="participant.contactLink" type="text" name="contactLink" required />
    </mat-form-field>
    
    <button mat-raised-button color="accent"  style="margin-top: 40px"(click)="save()">Spremi promjene</button>
</form>
