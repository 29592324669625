import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnDestroy {

  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $database : DatabaseService,
    public $auth : AuthService,
    public $loader : LoaderService
  ){
    let recipientId = data.recipientId;
    let participantId = this.$auth.participant.getValue().id;

    console.log(recipientId)
    console.log(participantId)

    this.subs.push(this.$database.messages.subscribe(mes => {
      this.messages = mes.filter(m => (m.recipientId == recipientId && m.participantId == this.$auth.participant.getValue().id) || (m.recipientId == this.$auth.participant.getValue().id && m.participantId == recipientId)).slice(-6)
      this.messages.filter(m => !m.read && m.recipientId == participantId).forEach(m => {
        this.$database.seeMessage(m.id)
      });
    }))
    this.subs.push(this.$database.participants.subscribe(parts => {
      this.recipient = parts.filter(p => p.id == recipientId)[0]
    }))
    this.subs.push(this.$database.participants.subscribe(parts => {
      this.participant = parts.filter(p => p.id == recipientId)[0]
    }))
  }

  public messages
  public participant
  public recipient
  public text

  sendMessage(){
    if(!this.text) return
    this.$loader.open()
    this.$database.createMessage(this.text, this.$auth.participant.getValue(), this.participant.id).then(() => {
      this.$loader.close();
      this.text = '';
      this.$loader.toast('Poruka uspješno poslana!')
    })
  }

}
