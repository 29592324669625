import { Component } from '@angular/core';
import { Router } from '@angular/router';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: []
})
export class AppComponent {
  title = 'educa';

  constructor(
    public $router : Router
  ){
    this.$router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}


