<div class="container">

    <mat-label style="margin-bottom: 10px;" *ngIf="participants.length">Sudionici:</mat-label>
    <mat-chip-list style="margin-bottom: 20px;" selectable multiple>
        <mat-chip color="accent" *ngFor="let participant of participants" 
        [selected]="subject.participantsIds.includes(participant.id)" 
        (click)="subject.participantsIds.includes(participant.id) ? subject.participantsIds.splice(subject.participantsIds.indexOf(participant.id), 1) : subject.participantsIds.push(participant.id)">{{participant.name}}</mat-chip>
        
      </mat-chip-list>

      <mat-label style="margin-bottom: 10px;" *ngIf="teachers.length">Predavači:</mat-label>
      <mat-chip-list style="margin-bottom: 20px;" selectable multiple>
          <mat-chip color="accent" *ngFor="let participant of teachers" 
          [selected]="subject.participantsIds.includes(participant.id)" 
          (click)="subject.participantsIds.includes(participant.id) ? subject.participantsIds.splice(subject.participantsIds.indexOf(participant.id), 1) : subject.participantsIds.push(participant.id)">{{participant.name}}</mat-chip>
          
        </mat-chip-list>

        <mat-label style="margin-bottom: 10px;" *ngIf="organizers.length">Organizatori:</mat-label>
        <mat-chip-list style="margin-bottom: 20px;" selectable multiple>
            <mat-chip color="accent" *ngFor="let participant of organizers" 
            [selected]="subject.participantsIds.includes(participant.id)" 
            (click)="subject.participantsIds.includes(participant.id) ? subject.participantsIds.splice(subject.participantsIds.indexOf(participant.id), 1) : subject.participantsIds.push(participant.id)">{{participant.name}}</mat-chip>
            
          </mat-chip-list>

          <mat-label style="margin-bottom: 10px;" *ngIf="administrators.length">Administratori:</mat-label>
          <mat-chip-list style="margin-bottom: 20px;" selectable multiple>
              <mat-chip color="accent" *ngFor="let participant of administrators" 
              [selected]="subject.participantsIds.includes(participant.id)" 
              (click)="subject.participantsIds.includes(participant.id) ? subject.participantsIds.splice(subject.participantsIds.indexOf(participant.id), 1) : subject.participantsIds.push(participant.id)">{{participant.name}}</mat-chip>
              
            </mat-chip-list>

      <button mat-raised-button color="accent" style="margin-top:40px" (click)="save()">SPREMI PROMJENE</button>
</div>