import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialog } from '@angular/material/dialog';
import { Classroom, Participant, Project } from 'models';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';
import { LoaderService } from '../loader.service';
import { ClassroomsEditComponent } from './classrooms-edit/classrooms-edit.component';

@Component({
  selector: 'app-classrooms',
  templateUrl: './classrooms.component.html',
  styleUrls: ['./classrooms.component.css']
})
export class ClassroomsComponent implements OnInit, OnDestroy {
  constructor(
    public $database : DatabaseService,
    public $auth : AuthService,
    public $dialog : MatDialog,
    public $loader: LoaderService,
    public $functions : AngularFireFunctions
  ){
  
  }

  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }

  async ngOnInit() {
    await new Promise((resolve, reject) => {
      this.subs.push(this.$auth.participant.subscribe(participant => {
        this.participant = participant
        if(this.participant) resolve(true);
      }))
    }) 
    
    await new Promise((resolve, reject) => {
      this.subs.push(this.$auth.selectedProject.subscribe(project => {
        this.project = project
        if(this.project) resolve(true);
      }))
    }) 
    
    this.subs.push(this.$database.classrooms.subscribe(classrooms => {
      this.classrooms = classrooms.filter(p => p.projectId == this.project?.id)
      this.classrooms.forEach(c => {
        c.subjects = this.$database.subjects.getValue().filter(s => s.classroomId == c.id)
      })
    }))
  }

  public project : Project | undefined;
  public participant : Participant | undefined;
  public classrooms : Classroom[] = [];
  public selectedClassroom : Classroom | undefined;
  public filter = "";

  edit(classroom : Classroom){
    this.$dialog.open(ClassroomsEditComponent, {
      data: {classroom, projectId:this.project?.id},
      width: '600px'
    }).afterClosed()
  }

  create(){
    this.$dialog.open(ClassroomsEditComponent, {
      data: {classroom : false, projectId:this.project?.id},
      width: '600px'
    }).afterClosed()
  }

  delete(classroom){
    this.$database.deleteClassroom(classroom.id);
    this.selectedClassroom = undefined;
  }

  openMeeting(){
    if(this.selectedClassroom.type == 'virtual'){ 
      this.$loader.open();
      console.log(this.participant.email)
      this.$functions.httpsCallable('addParticipantToMeeting')({
        email: this.$auth.participant.getValue().email,
        id: this.selectedClassroom.linkId
      }).toPromise().then(res => {
        this.$loader.close();
        window.open(this.selectedClassroom.link, '_blank');
      }).catch(error => {
        console.log(error)
      })
    } else {
      window.open('http://maps.google.com/?q=' + encodeURI(this.selectedClassroom.location), '_blank')
    }
    
  }
}
