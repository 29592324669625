import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';
import { DatabaseService } from 'src/app/database.service';
import { Project } from 'models';
import { LoaderService } from 'src/app/loader.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-projects-edit',
  templateUrl: './projects-edit.component.html',
  styleUrls: ['./projects-edit.component.css']
})
export class ProjectsEditComponent {
  constructor(
    public dialogRef: MatDialogRef<ProjectsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public $database : DatabaseService,
    public $loader : LoaderService
  ) {
    if (data.project) {
      this.project = data.project;
      this.exists = true;
    } else {
      this.project = {
        id: Guid.create().toString(),
        name: "",
        image: "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: "",
        programmesIds: [],
        subjectsIds: [],
        participantsIds: [],
        messagesIds: []
      }
      this.exists = false;
    }
  }

  public project: Project;
  public exists = false;

  @ViewChild('myForm') myForm: NgForm;

  public save() {

    if(!this.project.name || !this.project.description){
      this.myForm.form.markAllAsTouched();
      this.$loader.toast('Nisu ispunjeni svi potrebni podatci!')
      return;
    }

    this.$loader.open()
    if (this.exists) {
      this.$database.updateProject(this.project.id, this.project.name, this.project.image, this.project.description).then(() =>{
        this.$loader.close();
        this.$loader.toast('Projekt uspješno ažuriran!')
      })
      this.dialogRef.close();
    } else {
      this.$database.createProject(this.project.name, this.project.image, this.project.description).then(() =>{
        this.$loader.close();
        this.$loader.toast('Projekt uspješno napravljen!')
      })
      this.dialogRef.close();
    }
  }


  public onFileSelected(event){
    this.$loader.open()
    const file = event.target.files[0];
    if (file) {
      this.$database.pushFileToStorage(file, file.name).then((res) => {
        this.project.image = res as string;
        this.$loader.close();
      })
    }
  }
}
