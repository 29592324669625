<div class="projects" *ngIf="participant">
    <mat-card class="card" *ngFor="let project of projects">
            <img mat-card-image class="card-image" [src]="project.image" alt="">
                <h3 class="card-title">{{project.name}}</h3>
                <p>{{project.description}}</p>
                <mat-divider></mat-divider>
                <div style="display: grid; grid-template-columns: auto 1fr; padding-top: 12px;">
                    <mat-icon>fork_right</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{project.programmesIds.length}} Programa</p>
                    <mat-icon>history_edu</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{project.subjectsIds.length}} Predmeta</p>
                    <mat-icon>people</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{project.participantsIds.length}} Sudionika</p>
                </div>
                <mat-divider></mat-divider>
            <div class="card-buttons">
                <button mat-raised-button color="accent" (click)="details(project)"><mat-icon>school</mat-icon> DETALJI</button>
                <button *ngIf="participant.role == 'admin'" class="short-card-button" (click)="edit(project)" mat-raised-button color="accent"><mat-icon>edit</mat-icon></button>
                <button *ngIf="participant.role == 'admin'" class="short-card-button" (click)="delete(project)" mat-raised-button color="accent"><mat-icon>delete</mat-icon></button>
            </div>
    </mat-card>
    <button *ngIf="participant.role == 'admin'" mat-raised-button color="accent" style="height: 72px; width: 72px;" (click)="create()"><mat-icon style="scale: 3;">add</mat-icon></button>




    <!-- <img [src]="text"> -->
</div>