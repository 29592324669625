import { Component, OnInit,OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Participant, Subject } from 'models';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';
import { LoaderService } from '../loader.service';
import { ParticipantsEditComponent } from '../participants/participants-edit/participants-edit.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit, OnDestroy {
  constructor(
    public $dialog : MatDialog,
    public $database : DatabaseService,
    public $auth : AuthService,
    public $loader : LoaderService
  ){}
  subs : Subscription[] = []
  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }
  ngOnInit(): void {
    this.subs.push(this.$auth.participant.subscribe((part) => {
      this.participant = part;
    
      this.subjects = this.$database.subjects.getValue().filter(s => this.participant.role == 'admin' || s.participantsIds.includes(this.participant.id));
    }))
  }

  edit(participant : Participant){
    this.$dialog.open(ParticipantsEditComponent, {
      data: {participant},
      width: '600px'
    })
  }

  resetPassword(){
    this.$loader.open();
    this.$auth.resetPassword(this.participant.email).then(() => {
      this.$loader.close('Poslan email za resetiranje lozinke')
    })
  }

  participant?:Participant;
  subjects: Subject[] = [];


}
